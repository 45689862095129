import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components";
import Navbar from "../components/navbar"
import Internalbannerblog from "../components/internalbanner-blog"
import Reformfooter from "../components/footer"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Yogaback from "../images/yoga2.jpg";


const Pageholder = styled.div`
h1{
  font-size: 2rem;
  color: #dc473d;
}
.dater{
  margin: 1em 0;
  font-size: .9rem;
  font-weight: 400;
}
.mainbodytext{
  p,li{
    font-size: .9rem;
    margin: 1em 0;
    font-weight: 300;
  }

  ul{
    padding: 2em;
    border-left: 2px solid #e5e5e5;
    li{
      list-style-type: disc;
      ul{
        border-left: unset;
      }
    }
  }

  h2,h3,h4,h5{
    font-size: 1.2rem;
    margin: 1em 0;
    font-weight: 600;
    color: #dc473d;
  }
}

.nextprev{
  color: #dc473d;
}
`;



const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Pageholder>
    <Layout location={location} title={siteTitle}>
        <Navbar />
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
<Internalbannerblog title={post.frontmatter.title} subtitle="Reform Pilates Cork" bannerpic={Yogaback}/>
<div className="container">
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
    <div className="columns is-marginless">
    <div className="column is-half">
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p className="dater">{post.frontmatter.date}</p>
    </div>
      </div>

        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
          className="mainbodytext"
        />
        <hr />
      </article>
      <nav className="blog-post-nav">
    <div className="columns is-marginless is-mobile">

          <div className="column is-4">
  
            {previous && (
              <Link to={previous.fields.slug} rel="prev" className="nextprev">
                ← {previous.frontmatter.title}
              </Link>
            )}

          </div>

          <div className="column is-4 is-offset-4">

            {next && (
              <Link to={next.fields.slug} rel="next" className="nextprev has-text-right">
                {next.frontmatter.title} →
              </Link>
            )}

            </div>
            </div>
      </nav>
      </div>
           <Reformfooter />
    </Layout>
    </Pageholder>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
