import * as React from "react"
import styled from "styled-components";

const Blogbannerholder = styled.div`
.internal-page-banner{
background-color: #000;
padding-top: 3rem;
	height: 30vh;
	filter: hue-rotate(45deg) sepia(.4) grayscale(.3);
	margin-bottom: 2em;
	h1{
		color: #fff;
		font-size: 3rem;
		font-weight: 600;
	}
}
`;

const Internalbanner = (props) => {
	const {title} = props
	return (
		<Blogbannerholder>
		<section className="internal-page-banner">
		<div className="container hundheight">
		<div className="columns is-marginless is-centered level moblevel hundheight">
		<div className="column is-9 has-text-centered">
		<h1>{title}</h1>
		</div>
		</div>
		</div>
		</section>
		</Blogbannerholder>
		);
}

export default Internalbanner